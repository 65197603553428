.box {
  padding: 0.426667rem 0.346667rem 0.133333rem;
  background-color: #fff;
}

.title {
  margin-bottom: 0.133333rem;
  font-size: 0.48rem;
  font-weight: normal;
  color: #000;
}