:global #root {
  margin: 0 auto;
  max-width: 1024px;
}

body {
  font-family: Helvetica;
}

.caption a:first-child {
  position: relative;
  z-index: 1;
}

.caption a + div {
  margin-left: -1.333333rem;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}