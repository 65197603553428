.share {
  height: 1.493333rem;
  position: relative;
}

.shareFix {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}